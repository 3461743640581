import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Setting } from 'src/app/models/Setting';

export interface SettingsState extends EntityState<Setting, string>{}


@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'setting', idKey: 'key' })
export class SettingsStore extends EntityStore<SettingsState> {
    constructor() {
        super();
    }
}