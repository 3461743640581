import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  availableLanguages = [
    // 'es',
    'zh'
  ];

  constructor(private translateService: TranslateService

  ) { }

  getlanguage() {
    let lang = this.translateService.defaultLang;
    if (this.translateService.currentLang) {
      lang = this.availableLanguages.includes(this.translateService.currentLang) ? this.translateService.currentLang : lang;
    } else {
      lang = this.availableLanguages.includes(this.translateService.getBrowserLang() || lang) ? this.translateService.getBrowserLang() || lang : lang;
    }
    return lang;
  }
}
