import { HotDealsService } from 'src/app/services/api/hot-deals.service';
import { Observable, finalize, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Airport } from 'src/app/models/FlightSearchResponse';
import { SettingsStore } from './settings.store';
import { Position } from '@capacitor/geolocation';
import { AirportsStoreService } from '../airports/airports.service';
import { AirportsQuery } from '../airports/airports.query';
import { SettingsService } from 'src/app/services/api/settings.service';
import { Setting } from 'src/app/models/Setting';
import { SettingsQuery } from './settings.query';

@Injectable({ providedIn: 'root' })
export class SettingsStoreService {
    constructor(
        private settingsService: SettingsService,
        private store: SettingsStore,
        private query: SettingsQuery,
    ) {
    }

    get(key: string, defaultValue?: any): Observable<Setting | undefined> {
        if (this.query.hasEntity(key)) {
            return this.query.selectEntity(key)
        }
        this.store.add({ key, value: defaultValue } as Setting)
        return this.settingsService.getSetting(key).pipe(tap(airport => {
            this.store.update(key, airport)
        }))
    }
}