import { Injectable } from '@angular/core';
import ApiClientService from '../api-client.service';
import { Setting } from 'src/app/models/Setting';
import { Observable, take, tap } from 'rxjs';
import { SettingsStore } from 'src/app/stores/settings/settings.store';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private apiClient: ApiClientService, private store: SettingsStore) { }

  getSetting(key: string): Observable<Setting> {
    return this.apiClient.get(`config/${key}`, {}, {skipToast: true}).pipe(take(1)).pipe(
      tap((res) => {
        this.store.add(res);
      })
    );
  }
}
