import { createReducer, on } from '@ngrx/store';
import * as DataActions from './flight-booking.action';
import { AirlineMap, AirportMap } from 'src/app/models/mapString';
import { FlightOffer } from 'src/app/models/autogen-models/flightOffer';
import { BookingRequirements } from 'src/app/models/autogen-models/bookingRequirements';
import { Traveler } from 'src/app/models/autogen-models/traveler';
import { FlightPriceIncludeBags, OfferFareRules } from 'src/app/models/FlightPriceResponse';
import { BookingFormContact } from 'src/app/models/FlightOfferBooking';
import { ItinerariesAdditionalBags } from 'src/app/models/FlightAdditionalBags';
import * as moment from "moment";

export interface FlightBookingState {
  offer?: FlightOffer;
  bookingRequirements?: BookingRequirements;
  bags: FlightPriceIncludeBags;
  fareRules?: OfferFareRules[];
  travelers: Traveler[];
  contact?: BookingFormContact;
  selectedAdditionalBags?: ItinerariesAdditionalBags[];
  offerExpireDate?: Date;
}

export const initialState: FlightBookingState = {
  bags: {},
  travelers: [],
  selectedAdditionalBags: []
};

export const flightBookingReducer = createReducer(
  initialState,
  on(
    DataActions.setFlightBooking,
    (state, { offer, bags, bookingRequirements }) => ({
      ...initialState,
      offer,
      bags,
      bookingRequirements,
      offerExpireDate: moment(new Date()).add(15, 'm').toDate()
    })
  ),
  on(DataActions.startFlightBooking, (state, { travelers, contact }) => ({
    ...state,
    travelers,
    contact,
  })),
  on(DataActions.updateFlightBookingOffer, (state, { offer, fareRules }) => ({
    ...state,
    offer,
    fareRules
  })),
  on(DataActions.updateFlightFareRules, (state, { fareRules }) => ({
    ...state,
    fareRules
  })),
  on(DataActions.resetFlightBooking, (state, { }) => initialState),
  on(DataActions.updateFlightAdditionalBags, (state, { additionalBags }) => {
    const offer: FlightOffer = { ...state.offer }

    const additionalBagsWithSegments: ItinerariesAdditionalBags[] = additionalBags.map(selectedBag => {
      let segmentIds: string[] = []
      state.offer?.itineraries?.forEach((iti, index) => {
        if (String(index) === selectedBag.itineraryId) {
          segmentIds = iti.segments.map(seg => seg.id || '')
        }
      });
      return { ...selectedBag, segmentIds }
    })

    const addedBags: ItinerariesAdditionalBags[] = []
    offer.travelerPricings = offer.travelerPricings?.map((traveler) => {
      return {
        ...traveler,
        fareDetailsBySegment: traveler.fareDetailsBySegment.map(
          (segment) => {
            const bag = additionalBagsWithSegments.find(bag => bag.travelerId === traveler.travelerId && bag.segmentIds?.includes(segment.segmentId))
            const quantity = bag?.additionalBag.quantity || 0
            if (bag?.additionalBag.quantity) {
              addedBags.push(bag)
            }
            if (quantity) {
              return {
                ...segment,
                additionalServices: {
                  ...segment.additionalServices,
                  chargeableCheckedBags: {
                    quantity,
                  },
                }
              }
            }
            return {
              ...segment,
              additionalServices: undefined
            };
          }
        ),
      };
    })

    return {
      ...state,
      offer,
      selectedAdditionalBags: additionalBags,
    }
  })
);
