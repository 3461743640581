import { createAction, createSelector, props } from '@ngrx/store';
import { FlightSearch } from 'src/app/models/FlightSearch';
import { FlightSearchFilter, OffersSortType } from 'src/app/models/FlightSearchFilters';
import { FlightOffer } from 'src/app/models/autogen-models/flightOffer';
import { FlightSearchState, initialState, selectFilter } from './flight-search.reducer';
import { max } from 'moment';
import { AirlineMap, AirportMap } from 'src/app/models/mapString';
import { Airline } from 'src/app/models/FlightSearchResponse';

export const setFlightSearch = createAction(
  '[Data] Load Data flight search',
  props<{ search: FlightSearch }>()
);
export const setSearchFilter = createAction(
  '[Data] Load Data flight search filter',
  props<{ filter: FlightSearchFilter }>()
);
export const setSearchResult = createAction(
  '[Data] Load Data flight offers',
  props<{ offers: FlightOffer[], airlinesCode?: string[] }>()
);

export const setOffersSort = createAction(
  '[Data] Sort flight offers',
  props<{ sortType: OffersSortType }>()
);

export const resetSearch = createAction(
  '[Data] Reset search'
);

// Selectors
export const selectFlightSearchState = (state: FlightSearchState) => state;

export const selectFlightSearch = createSelector(
  selectFlightSearchState,
  (state) => state.search
);

export const selectFlightFilter = createSelector(
  selectFlightSearchState,
  (state) => state.filter
);

export const selectFlightOffers = createSelector(
  selectFlightSearchState,
  (state) => state.offers
);

export const updateFilterBasedOnSearch = createAction(
    '[Flight Search] Update Filter Based On Search',
    props<{ search: FlightSearch }>()
  );

