import { Duration } from './duration';

export interface ConnectionChoiceFilter {
  zero: boolean;
  one: boolean;
  twoUp: boolean;
}

export interface DepartureTimeRangeFilter {
  lower: Date;
  upper: Date;
}

export interface DepartureTimeFilter {
  departureFlight: DepartureTimeRangeFilter;
  returnFlight?: DepartureTimeRangeFilter;
}

export interface FlightSearchFilter {
  connection0: boolean;
  connection1: boolean;
  connection2up: boolean;
  departureTime: DepartureTimeRangeFilter;
  returnTime?: DepartureTimeRangeFilter;
  flightHours?: Duration;
  airlinesCodes?: string[];
}

export type OffersSortType = 'BEST' | 'CHEAPEST' | 'FASTEST';

export const OffersSortType = {
  BEST: 'BEST' as OffersSortType,
  CHEAPEST: 'CHEAPEST' as OffersSortType,
  FASTEST: 'FASTEST' as OffersSortType,
};
